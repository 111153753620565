<template>
  <div class="card has-border-radius has-background-white-ter shadow-none p-4">
    <div
      class="h-2 has-border-radius w-full"
      :class="{
        'progress-gradient': !pasivo,
        'progress-gradient-pasivo': pasivo
      }"
    />
    <p class="text-xl font-bold text-left mb-3 mt-2">
      Resumen de
      {{
        $route.params.idLinea
          ? `línea #${$route.params.idLinea}`
          : "nueva línea"
      }}
    </p>
    <div class="border mb-3" />
    <div class="my-2">
      <p class="text-left has-text-grey">{{ tieneProductoCreditoGrupal ? 'Grupo' : 'Cliente' }}</p>
      <p class="text-left">
        <span v-if="form.persona_id">{{ cliente }}</span>
        <span v-else class="has-text-danger">
          N/A
        </span>
      </p>
    </div>
    <div class="my-2">
      <p class="text-left has-text-grey">Sucursal</p>
      <p class="text-left">
        <span v-if="form.sucursal_id">
          {{ getMe.sucursales.find(x => x.id == form.sucursal_id).nombre }}
        </span>
        <span v-else class="has-text-danger">
          N/A
        </span>
      </p>
    </div>
    <div class="my-2">
      <p class="text-left has-text-grey">Producto financiero</p>
      <p class="text-left">
        <span v-if="form.producto_financiero_id && producto_financiero">
          {{ producto_financiero.alias }}
        </span>
        <span v-else class="has-text-danger">
          N/A
        </span>
      </p>
    </div>
    <div class="my-2">
      <p class="text-left has-text-grey">Segmento de negocio</p>
      <p class="text-left">
        <span v-if="form.segmento_id">
          {{ segmento_negocio.nombre }}
        </span>
        <span v-else class="has-text-danger">
          N/A
        </span>
      </p>
    </div>
    <div class="my-2">
      <p class="text-left has-text-grey">Tipo de garantía</p>
      <p class="text-left">
        <span v-if="form.tipo_garantia_id">
          {{
            getTiposGarantiaLinea.find(x => x.id == form.tipo_garantia_id)
              .nombre
          }}
        </span>
        <span v-else class="has-text-danger">
          N/A
        </span>
      </p>
    </div>
    <div class="my-2">
      <p class="text-left has-text-grey">Revolvente</p>
      <p class="text-left">
        {{ form.revolvente ? "Sí" : "No" }}
      </p>
    </div>
    <div class="border mb-3" />
    <b-button
      type="is-text"
      expanded
      class="has-text-primary text-center"
      @click="showExtras = !showExtras"
    >
      {{ showExtras ? "Ocultar extras" : "Ver extras" }}
    </b-button>
    <!-- Garantias -->
    <div v-if="getDataCreacion.garantias && showExtras" class="text-left">
      <p class="font-bold mb-2">Garantías</p>
      <div
        class="card has-background-grey-lighter has-border-radius p-2 shadow-none my-2"
        v-for="garantia in getDataCreacion.garantias.garantias"
        :key="garantia.id"
      >
        {{ garantia.descripcion }}
      </div>
    </div>
    <!-- Personas -->
    <div v-if="getDataCreacion.personas && showExtras" class="text-left">
      <p class="font-bold mb-2">Personas relacionadas</p>
      <div
        class="card has-background-grey-lighter has-border-radius p-2 shadow-none my-2"
        v-for="persona in getDataCreacion.personas"
        :key="persona.id"
      >
        <div class="columns">
          <div class="column flex items-center align-center">
            <icon-initials
              size="small"
              class="m-auto"
              :nombre="persona.relacionado.nombre_completo"
            />
          </div>
          <div class="column is-10">
            <p>{{ persona.relacionado.nombre_completo.split("|")[0] }}</p>
            <p class="has-text-grey-light">
              {{ persona.rol.nombre }} #{{ persona.relacionado.id }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Requisitos -->
    <div v-if="getDataCreacion.requisitos && showExtras" class="text-left">
      <p class="font-bold mb-2">Requisitos</p>
      <div
        class="card has-background-grey-lighter has-border-radius p-2 shadow-none my-2"
      >
        {{ getDataCreacion.requisitos.count }} Requisitos agregados
      </div>
    </div>
    <!-- Contrato -->
    <div v-if="getDataCreacion.contrato && showExtras" class="text-left">
      <p class="font-bold mb-2">Contrato</p>
      <div
        class="card has-background-grey-lighter has-border-radius p-2 shadow-none my-2"
      >
        <span v-if="getDataCreacion.contrato.capital">
          de {{ getDataCreacion.contrato.capital | currency }}
        </span>
        <span v-if="getDataCreacion.contrato.referencia">
          con referencia
          {{ getDataCreacion.contrato.referencia }}
        </span>
        <span v-if="getDataCreacion.contrato.ciclos.length > 0">
          con
          {{ getDataCreacion.contrato.ciclos.length }}
          {{ getDataCreacion.contrato.ciclos.length == 1 ? "ciclo" : "ciclos" }}
        </span>
      </div>
    </div>
    <!-- Fechas -->
    <div v-if="getDataCreacion.fechas && showExtras" class="text-left">
      <p class="font-bold mb-2">Fechas</p>
      <div
        class="card has-background-grey-lighter has-border-radius p-2 shadow-none my-2"
        v-for="fecha in fechasExtras"
        :key="fecha.nombre"
      >
        {{ fecha.nombre }}: {{ fecha.fecha | moment("DD-MM-YYYY") }}
      </div>
    </div>
    <!-- {{ getDataCreacion }} -->
    <div class="border mb-3" />
    <div class="columns is-vcentered">
      <div class="column text-left">
        Capital
      </div>
      <div class="column text-right">
        <p
          class="text-3xl font-regular"
          :class="{
            'has-text-primary': !pasivo,
            'has-text-pasivo': pasivo,
            'has-text-danger': form.capital <= 0
          }"
        >
          {{ form.capital | currency | hp }}
        </p>
        <p>
          {{
            getMonedas.length > 0 && form.moneda_id != null
              ? getMonedas.find(x => x.id == form.moneda_id).nombre
              : ""
          }}
        </p>
      </div>
    </div>
    <b-button
      :type="pasivo ? 'is-pasivo' : 'is-primary'"
      expanded
      @click="validate().then(res => createLinea(res))"
      class="font-bold py-0"
      >{{ $route.params.idLinea ? "Editar" : "Crear" }} línea de
      crédito</b-button
    >
    <b-button
      type="is-white"
      class="mt-4 has-text-danger font-bold"
      expanded
      @click="$router.back(1)"
    >
      Cancelar</b-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconInitials from "@/components/generals/IconInitials";

export default {
  name: "LineaResumen",
  props: {
    form: Object,
    pasivo: Boolean,
    cliente: String,
    ciclos: Array,
    validate: Function,
    tieneProductoCreditoGrupal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showExtras: false
    };
  },
  components: {
    IconInitials
  },
  methods: {
    /*
    Función encargada de crear una línea de crédito
    @res (Boolean) validación de parte de vee-validate de los campos
    */
    createLinea(res) {
      if (!res) {
        return;
      }
      let main_data = {
        sucursal_id: this.form.sucursal_id,
        capital_autorizado: this.form.capital,
        productos_financieros_id: this.form.producto_financiero_id,
        segmentos_negocio_id: this.form.segmento_id,
        moneda_id: this.form.moneda_id,
        tipos_garantia_id:
          this.form.tipo_garantia_id == 0 ? null : this.form.tipo_garantia_id,
        revolvente: this.form.revolvente,
        acreditado_id: this.form.persona_id,
        grupo_credito: this.tieneProductoCreditoGrupal,
      };
      let dispatch = "lineascredito/crear";
      if (this.$route.params.idLinea) {
        main_data.linea_id = this.$route.params.idLinea;
        dispatch = "lineascredito/editar";
      }
      this.$store
        .dispatch(dispatch, { pasiva: this.pasivo, data: main_data })
        .then(data => {
          if (!data) return;
          this.addGarantias(data.id);
          this.addPersonas(data.id);
          this.addRequisitos(data.id);
          this.addContrato(data.id);
          this.addFechas(data.id);
          if (! this.tieneProductoCreditoGrupal) {
            this.$emit("finalizado", true);
          }
          
          if (this.tieneProductoCreditoGrupal) {
            this.$router.push({
              name: "solicitar_credito_grupal",
              params: { linea_credito_id: data.id}
            });
          }
        })
        .finally(() => {
          this.$store.dispatch("lineascredito/clearDataCreacion");
        });
    },
    /*
      Se encarga de agregar las garantías a la línea de crédito recien creada en caso de que existan garantías agregadas
      @id (Int): id de la línea recien creada
    */
    addGarantias(id) {
      if (!this.getDataCreacion.garantias) return;
      this.$store.dispatch("lineascredito/setGarantias", {
        linea_credito_id: id,
        aforo: this.getDataCreacion.garantias.aforo,
        garantias: this.getDataCreacion.garantias.garantias.map(x => x.id)
      });
    },
    /*
      Se encarga de agregar las personas relacionadas a la línea de crédito recien creada en caso de que existan personas agregadas
      @id (Int): id de la línea recien creada
    */
    addPersonas(id) {
      if (!this.getDataCreacion.personas) return;
      this.$store.dispatch("lineascredito/setPersonas", {
        linea_credito_id: id,
        personas: this.getDataCreacion.personas.map(x => {
          return {
            id: x.persona2_id,
            nombre_rol: x.rol.nombre
          };
        })
      });
    },
    /*
      Se encarga de agregar requisitos a la línea de crédito recien creada en caso de que existan requisitos agregados
      @id (Int): id de la línea recien creada
    */
    addRequisitos(id) {
      if (!this.getDataCreacion.requisitos) return;
      if (
        this.getDataCreacion.requisitos.manual.filter(x => x.checked == true)
          .length > 0
      ) {
        this.$store
          .dispatch(
            "lineascredito/createRequisitos",
            this.getDataCreacion.requisitos.manual.filter(
              x => x.checked == true
            )
          )
          .then(data => {
            this.$store.dispatch("lineascredito/setRequisitos", {
              linea_credito_id: id,
              requisitos: data
                .map(x => x.id)
                .concat(this.getDataCreacion.requisitos.selected)
            });
          });
      } else {
        this.$store.dispatch("lineascredito/setRequisitos", {
          linea_credito_id: id,
          requisitos: this.getDataCreacion.requisitos.selected
        });
      }
    },
    /*
      Se encarga de agregar el contrato a la línea de crédito recien creada
      @id (Int): id de la línea recien creada
    */
    async addContrato(id) {
      if (!this.getDataCreacion.contrato) return;

      if (this.$route.params.idLinea) {
        await this.$store.dispatch(
          "lineascredito/getContrato",
          this.$route.params.idLinea
        );
      } else {
        await this.$store.dispatch("lineascredito/resetContrato");
      }

      const action = this.getContrato
        ? "lineascredito/setContrato"
        : "lineascredito/createContrato";

      if (
        !this.getDataCreacion.contrato || 
        !this.getDataCreacion.contrato.capital ||
        !this.getDataCreacion.contrato.numero ||
        this.getDataCreacion.contrato.ciclos.length < 1
      ) {
        return;
      }

      this.$store.dispatch(action, {
        contrato_id: this.getContrato ? this.getContrato.id : null,
        capital: this.getDataCreacion.contrato.capital,
        numero: this.getDataCreacion.contrato.numero,
        descripcion: this.getDataCreacion.contrato.descripcion,
        fecha_firma: this.getDataCreacion.contrato.fecha_firma
          ? this.$moment(this.getDataCreacion.contrato.fecha_firma).format(
              "YYYY-MM-DD"
            )
          : null,
        vencimiento_contrato: this.getDataCreacion.contrato.vencimiento_contrato
          ? this.$moment(
              this.getDataCreacion.contrato.vencimiento_contrato
            ).format("YYYY-MM-DD")
          : null,
        linea_credito_id: id,
        ciclos: this.getDataCreacion.contrato.ciclos.map(x => {
          return {
            fecha_inicial: this.$moment(x[0]).format("YYYY-MM-DD"),
            fecha_final: this.$moment(x[1]).format("YYYY-MM-DD")
          };
        })
      });
    },
    /*
      Se encarga de agregar las fechas extras a la línea de crédito recien creada
      @id (Int): id de la línea recien creada
    */
    addFechas(id) {
      if (!this.getDataCreacion.fechas) return;

      let fechaEsNull = false;
      for (var key of Object.keys(this.getDataCreacion.fechas)) {
        this.getDataCreacion.fechas[key] = this.getDataCreacion.fechas[key]
          ? this.$moment(this.getDataCreacion.fechas[key]).format("YYYY-MM-DD")
          : null;

        if (!this.getDataCreacion.fechas[key]) {
          fechaEsNull = true;
        }
      }
      if (fechaEsNull) {
        return;
      }

      this.$store.dispatch("lineascredito/setFechas", {
        linea_credito_id: id,
        fechas: this.getDataCreacion.fechas
      });
    }
  },
  async mounted() {
    if (!this.getSegmentoNegocio) {
      await this.$store.dispatch("catalogos/getSegmentoNegocio");
    }
    if (!this.getProductosFinancieros) {
      await this.$store.dispatch("catalogos/getProductosFinancieros");
    }
    if (!this.getMonedas) {
      await this.$store.dispatch("catalogos/getMonedas");
    }
    if (!this.getTiposGarantiaLinea) {
      await this.$store.dispatch("catalogos/getTiposGarantiaLinea");
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getMonedas",
      "getTiposGarantiaLinea",
      "getSegmentoNegocio",
      "getProductosFinancieros"
    ]),
    ...mapGetters(["getMe"]),
    ...mapGetters("lineascredito", [
      "getLinea",
      "getDataCreacion",
      "getContrato"
    ]),
    producto_financiero() {
      if (!this.getProductosFinancieros) return "N/A";

      return this.form.producto_financiero_id
        ? this.getProductosFinancieros.find(
            x => x.id == this.form.producto_financiero_id
          )
        : "N/A";
    },
    segmento_negocio() {
      if (!this.getSegmentoNegocio) return "N/A";

      return this.form.segmento_id
        ? this.getSegmentoNegocio.find(x => x.id == this.form.segmento_id)
        : "N/A";
    },
    fechasExtras() {
      let fechas = [];
      let fecha_key = "";
      for (var key in this.getDataCreacion.fechas) {
        if (this.getDataCreacion.fechas[key]) {
          switch (key) {
            case "fecha_caducidad":
              fecha_key = "Fecha de caducidad";
              break;
            case "fecha_cambio_fuente_fondeo":
              fecha_key = "Fecha máxima para cambiar fuente de fondeo";
              break;
            case "fecha_contratacion":
              fecha_key = "Fecha máxima de contratación";
              break;
            case "fecha_disposicion":
              fecha_key = "Fecha máxima de disposición";
              break;
          }
          fechas.push({
            nombre: fecha_key,
            fecha: this.getDataCreacion.fechas[key]
          });
        }
      }
      return fechas;
    },
  }
};
</script>
