<template>
  <div class="columns is-multiline">
    <div class="column is-8">
      <p class="font-medium text-lg">Requisitos agregados</p>
      <p>Quita o agrega requisitos de la lista.</p>
    </div>
    <div class="column text-right">
      <b-button type="is-primary" outlined @click="openModalAddRequisito"
        >Agrega requisito</b-button
      >
    </div>
    <div class="column is-12">
      <b-tag
        class="cursor-pointer tag-clickable mr-3"
        @click.native="setActiveTag(tipo.id)"
        :class="{ pasivo: pasivo, 'is-active': tipo.id == activeTag }"
        v-for="tipo in getTiposRequisito"
        :key="tipo.id"
      >
        {{ tipo.nombre }}
        ({{ totalPorTipo(tipo.id) }})
      </b-tag>
    </div>
    <div v-if="requisitosManual.length > 0" class="column">
      <b-checkbox
        size="is-medium"
        v-model="isSelectAll"
        @change.native="selectAll"
      >
        {{ !isSelectAll ? "Seleccionar todo" : "Deseleccionar todo" }}
      </b-checkbox>
    </div>
    <div class="column is-12 pb-0">
      <div
        class="card mb-3 p-3 has-border-radius flex"
        :class="{
          'has-background-primary-16': requisito.checked,
          'has-background-white-ter': !requisito.checked
        }"
        v-for="requisito in requisitosManual"
        :key="requisito.nombre"
      >
        <div class="flex items-center w-4/5">
          <b-checkbox size="is-medium" v-model="requisito.checked" />
          <p class="font-medium ml-8">
            {{ requisito.nombre }}
          </p>
        </div>
        <div class="text-right w-1/5">
          <b-button
            type="is-text"
            class="has-text-primary"
            @click="seeDetail(requisito)"
          >
            Ver detalles
            <fa-icon icon="chevron-right" />
          </b-button>
        </div>
      </div>
    </div>
    <div
      class="column is-12 pt-0"
      v-if="getRequisitosProducto && getRequisitosProducto.id"
    >
      <div
        class="mb-3 p-3 has-border-radius flex shadow-none"
        :class="{
          'has-background-primary-16': form.requisitosSelected.includes(
            requisito.id
          ),
          'has-background-white-ter': !form.requisitosSelected.includes(
            requisito.id
          )
        }"
        v-for="requisito in requisitos"
        :key="requisito.id"
      >
        <div class="flex items-center w-4/5">
          <b-checkbox
            size="is-medium"
            v-model="form.requisitosSelected"
            :native-value="requisito.id"
          />
          <p class="font-medium ml-8">
            {{ requisito.nombre }}
          </p>
        </div>
        <div class="text-right w-1/5">
          <b-button
            type="is-text"
            class="has-text-primary"
            @click="seeDetail(requisito)"
          >
            Ver detalles
            <fa-icon icon="chevron-right" />
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-if="
        !getRequisitosProducto ||
          (!getRequisitosProducto.id && form.requisitosManual.length == 0)
      "
      class="w-full"
    >
      <empty-state
        type="svg"
        :vertical="true"
        imagen="/images/requisito_placeholder.svg"
        imgclass="mb-4"
        titulo="No has añadido requisitos"
        titulo-class="has-text-grey-light text-xl"
        subtitulo="Empieza a añadir requisitos"
        subtitulo-class="has-text-grey-light"
      />
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="isAddingRequisito"
        :active.sync="isAddingRequisito"
        has-modal-card
        trap-focus
        width="300"
        aria-role="dialog"
        aria-modal
      >
        <modal-nuevo-requisito
          :requisito="requisitoView"
          class="modal-has-overflow"
          @added="addRequisito"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";
import ModalNuevoRequisito from "@/components/modals/ModalNuevoRequisito";

export default {
  name: "ExtrasRequisitos",
  props: {
    productoId: Number,
    pasivo: Boolean
  },
  data() {
    return {
      form: {
        requisitosSelected: [],
        requisitosManual: []
      },
      isAddingRequisito: false,
      requisitoView: null,
      activeTag: null,
      isSelectAll: true
    };
  },
  components: {
    EmptyState,
    ModalNuevoRequisito
  },
  computed: {
    ...mapGetters("lineascredito", ["getRequisitosProducto"]),
    ...mapGetters("catalogos", ["getTiposRequisito"]),
    requisitos() {
      if (this.activeTag) {
        return this.getRequisitosProducto.requisitos.filter(
          x => x.tipo_requisito_id == this.activeTag
        );
      }
      return this.getRequisitosProducto.requisitos;
    },
    requisitosManual() {
      if (this.activeTag) {
        return this.form.requisitosManual.filter(
          x => x.tipo_requisito_id == this.activeTag
        );
      }
      return this.form.requisitosManual;
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$store.dispatch("lineascredito/setDataCreacion", {
          tipo: "requisitos",
          data: {
            manual: val.requisitosManual,
            selected: val.requisitosSelected,
            count:
              val.requisitosManual.filter(x => x.checked == true).length +
              val.requisitosSelected.length
          }
        });
      },
      deep: true
    },
    requisitosManual: {
      handler(requisitos) {
        const requisitosChecked = requisitos.filter(
          requisito => requisito.checked
        );
        if (requisitosChecked.length == this.requisitosManual.length) {
          this.isSelectAll = true;
        } else {
          this.isSelectAll = false;
        }
      },
      deep: true,
      inmediate: true
    }
  },
  methods: {
    openModalAddRequisito() {
      this.requisitoView = null;
      this.isAddingRequisito = true;
    },
    addRequisito(requisito) {
      this.form.requisitosManual.push(requisito);
    },
    totalPorTipo(tipo_id) {
      let total = 0;
      if (this.getRequisitosProducto && this.getRequisitosProducto.requisitos) {
        total += this.getRequisitosProducto.requisitos.filter(
          x => x.tipo_requisito_id == tipo_id
        ).length;
      }
      total += this.form.requisitosManual.filter(
        x => x.tipo_requisito_id == tipo_id
      ).length;
      return total;
    },
    setActiveTag(id) {
      if (this.activeTag == id) {
        this.activeTag = null;
      } else {
        this.activeTag = id;
      }
    },
    seeDetail(requisito) {
      this.requisitoView = requisito;
      this.isAddingRequisito = true;
    },
    selectAll() {
      this.requisitosManual.forEach(
        requisito => (requisito.checked = this.isSelectAll)
      );
    }
  },
  mounted() {
    if (this.getTiposRequisito == null) {
      this.$store.dispatch("catalogos/getTiposRequisito");
    }
  }
};
</script>
