<template>
  <div>
    <tabs :tabs="tabs" v-model="activeTab" type="default" class="mb-3" />
    <section v-show="activeTab == 0">
      <garantias ref="garantias" :persona-id="personaId" />
    </section>
    <section v-show="activeTab == 1">
      <personas ref="personas" :persona-id="personaId" />
    </section>
    <section v-show="activeTab == 2">
      <requisitos
        ref="requisitos"
        :producto-id="form.producto_financiero_id"
        :pasivo="pasivo"
      />
    </section>
    <section v-show="activeTab == 3">
      <contrato
        ref="contrato"
        :pasivo="pasivo"
        :capital="form.capital"
        @updatedCiclos="
          ciclosLength => this.$emit('updatedCiclos', ciclosLength)
        "
      />
    </section>
    <section v-if="activeTab == 4">
      <fechas ref="fechas" :fechas="fechas" :pasivo="pasivo" />
    </section>
  </div>
</template>

<script>
import Tabs from "@/components/generals/Tabs";
import Garantias from "@/components/Lineas/extras/Garantias";
import Personas from "@/components/Lineas/extras/Personas";
import Requisitos from "@/components/Lineas/extras/Requisitos";
import Contrato from "@/components/Lineas/extras/Contrato";
import Fechas from "@/components/Lineas/extras/Fechas";

export default {
  name: "ExtrasLinea",
  data() {
    return {
      activeTab: 0
    };
  },
  props: {
    personaId: Number,
    fechas: Object,
    form: Object,
    pasivo: Boolean
  },
  components: {
    Tabs,
    Garantias,
    Personas,
    Requisitos,
    Contrato,
    Fechas
  },
  methods: {},
  watch: {
    personaId(val) {
      this.$store.dispatch("personas/getGarantias", val);
      this.$store.dispatch("personas/getRelaciones", val);
    }
  },
  computed: {
    tabs() {
      return [
        {
          label: "Garantías"
        },
        {
          label: "Personas relacionadas"
        },
        {
          label: "Checklist"
        },
        {
          label: "Contrato"
        },
        {
          label: "Fechas"
        }
      ];
    }
  }
};
</script>
