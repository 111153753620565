<template>
  <layout class="main-cols">
    <finalizado v-if="finalizado" />
    <validation-observer
      v-else
      ref="observer"
      v-slot="{ validate }"
      class="w-full text-left"
    >
      <div class="container is-fluid lg:ml-0 pl-1">
        <div class="columns">
          <div class="column is-8 text-left">
            <div class="columns is-multiline">
              <div class="column is-12">
                <p class="text-3xl font-bold">
                  {{ $route.params.idLinea ? "Editar" : "Nueva" }} línea de
                  crédito
                  {{ $route.params.idLinea ? `#${$route.params.idLinea}` : "" }}
                </p>
                <b-steps v-model="activeStep">
                  <b-step-item label="Detalles de la línea" :clickable="true">
                    <div class="columns">
                      <div class="column is-6">
                        <valid-auto-complete
                          v-if="!pasivo && !tieneProductoCreditoGrupal"
                          :disabled="!!$route.params.clienteReestructuraRenovacionId"
                          rules="required"
                          label="Cliente"
                          placeholder="Buscar..."
                          v-model="cliente"
                          icon="search"
                          :data="getClientes"
                          show="alias"
                          class="text-left"
                          @typing="buscarClientes"
                          @select="setCliente"
                          :expanded="true"
                        />
                        <valid-auto-complete
                          v-if="pasivo && !tieneProductoCreditoGrupal"
                          :disabled="!!$route.params.clienteReestructuraRenovacionId"
                          rules="required"
                          label="Cliente"
                          placeholder="Buscar..."
                          v-model="cliente"
                          icon="search"
                          :data="getFuentesFondeo"
                          show="alias"
                          class="text-left"
                          @typing="buscarFuentesFondeo"
                          @select="setCliente"
                          :expanded="true"
                        />
                        <valid-auto-complete
                          v-if="!pasivo && tieneProductoCreditoGrupal"
                          :disabled="!!$route.params.clienteReestructuraRenovacionId"
                          rules="required"
                          label="Grupo"
                          placeholder="Buscar..."
                          v-model="cliente"
                          icon="search"
                          :data="gruposEconomicosCreditoFiltered"
                          show="nombre"
                          class="text-left"
                          @typing="buscarGrupoCredito"
                          @select="setCliente"
                          :expanded="true"
                        />
                        <valid-currency-input
                          rules="required|currency|notcero"
                          label="Capital de la línea"
                          label-class="mt-6"
                          placeholder="Introduzca un valor..."
                          v-model="form.capital"
                          :expanded="true"
                        />
                        <valid-select
                          rules="required"
                          label="Producto financiero"
                          placeholder="Selecciona un producto..."
                          field-class="mt-6"
                          v-model="form.producto_financiero_id"
                          :expanded="true"
                          :data="getProductosFinancieros"
                          @input="getPlantilla"
                          show="alias"
                          realValue="id"
                        />
                        <valid-select
                          rules
                          label="Tipo de garantía"
                          placeholder="Selecciona un tipo de garantía..."
                          field-class="mt-6"
                          v-model="form.tipo_garantia_id"
                          :expanded="true"
                          :data="tiposGarantia"
                          :disabled="esArrendamiento"
                          show="nombre"
                          realValue="id"
                        />
                      </div>
                      <div class="column is-6" v-if="getMe">
                        <valid-select
                          rules="required"
                          label="Sucursal"
                          placeholder="Selecciona una sucursal..."
                          v-model="form.sucursal_id"
                          :expanded="true"
                          :data="getMe.sucursales"
                          show="nombre"
                          realValue="id"
                        />
                        <valid-select
                          rules="required"
                          label="Tipo de moneda"
                          placeholder="Selecciona una moneda..."
                          field-class="mt-6"
                          v-model="form.moneda_id"
                          :expanded="true"
                          :data="getMonedas"
                          show="nombre"
                          realValue="id"
                        />
                        <valid-select
                          rules="required"
                          label="Segmento de negocio"
                          placeholder="Selecciona un tipo de segmento..."
                          field-class="mt-6"
                          v-model="form.segmento_id"
                          :expanded="true"
                          :data="getSegmentoNegocio"
                          show="nombre"
                          realValue="id"
                        />
                        <b-field label="Revolvente" class="mt-6">
                          <b-checkbox
                            class="mt-1"
                            size="is-medium"
                            v-model="form.revolvente"
                          />
                        </b-field>
                      </div>
                    </div>
                  </b-step-item>
                  <b-step-item label="Extras" :clickable="true">
                    <extras
                      :fechas="fechas"
                      :form="form"
                      :pasivo="pasivo"
                      :persona-id="form.persona_id"
                      ref="extras"
                      @updatedCiclos="ciclosLength => (ciclos = ciclosLength)"
                    />
                  </b-step-item>
                </b-steps>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <resumen
              class="resumen"
              :form="form"
              :pasivo="pasivo"
              :cliente="cliente"
              :ciclos="ciclos"
              :validate="validate"
              :tieneProductoCreditoGrupal="tieneProductoCreditoGrupal"
              @finalizado="setDone"
            />
          </div>
        </div>
      </div>
    </validation-observer>
  </layout>
</template>

<script>
import LineasCreditoApi from "@/api/lineascredito.api";
import CatalogosApi from "@/api/catalogos.api";
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import { debounce } from "@/vendors/helpers";
import ValidSelect from "@/components/form/ValidSelect";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import Extras from "@/components/Lineas/extras/Index";
import Resumen from "@/components/Lineas/Resumen";
import Finalizado from "@/components/Lineas/Finalizado";

export default {
  name: "CrearLinea",
  data() {
    return {
      activeStep: 0,
      activeTab: 0,
      cliente: null,
      ciclos: [],
      pasivo: false,
      finalizado: false,
      form: {
        persona_id: null,
        sucursal_id: null,
        capital: null,
        moneda_id: null,
        producto_financiero_id: null,
        segmento_id: null,
        tipo_garantia_id: null,
        revolvente: false
      },
      fechas: {},
      lineasReestructuraRenovacion: null,
      tieneProductoCreditoGrupal: false,
      gruposEconomicosCredito: [],
      gruposEconomicosCreditoFiltered: [],
    };
  },
  components: {
    ValidAutoComplete,
    ValidSelect,
    ValidCurrencyInput,
    Extras,
    Resumen,
    ValidationObserver,
    Finalizado
  },
  computed: {
    ...mapGetters("catalogos", [
      "getClientes",
      "getFuentesFondeo",
      "getMonedas",
      "getProductosFinancieros",
      "getSegmentoNegocio",
      "getTiposGarantiaLinea"
    ]),
    ...mapGetters("lineascredito", ["getLinea"]),
    ...mapGetters(["getMe"]),

    esArrendamiento() {
      const producto = this.getProductosFinancieros.find(producto => producto.id === this.form.producto_financiero_id);
      return producto && producto.tipo_credito.tipo === 'Arrendamiento Puro' ? true : false;
    },
    tiposGarantia() {
      if (!this.getTiposGarantiaLinea) return [];
      let tiposGarantia = this.getTiposGarantiaLinea.concat(
        [
          { id: 0, nombre: "Sin garantía" }
        ]
      );

      if (this.esArrendamiento) {
        tiposGarantia = tiposGarantia.map(
          garantia => { 
            if(garantia.nombre === "Bienes muebles u otras garantías previstas en el Artículo 32 A del Reglamento del Registro Público de Comercio") {
              garantia.nombre = "Bienes muebles";
            } 
            return garantia;
          }
        );
      }

      return tiposGarantia;
    }
  },
  methods: {
    buscarClientes: debounce(function(nombre) {
      if (!nombre.length || nombre.length <= 3) {
        return;
      }
      this.$store.dispatch("catalogos/buscarClientes", nombre);
    }, 400),
    buscarFuentesFondeo: debounce(function(nombre) {
      if (!nombre.length || nombre.length <= 3) {
        return;
      }
      this.$store.dispatch("catalogos/getFuentesFondeo", nombre);
    }, 400),
    buscarGrupoCredito: debounce(function(nombre) {
      if (!nombre.length || nombre.length <= 3) {
        if (nombre.length < 1) this.gruposEconomicosCreditoFiltered = this.gruposEconomicosCredito;
        return;
      }
      
      const search = nombre.toLowerCase();

      const coincidences = this.gruposEconomicosCredito.filter(grupo => grupo.nombre.toLowerCase().indexOf(search) >= 0);

      this.gruposEconomicosCreditoFiltered = coincidences;
      
    }, 400),
    setCliente(val) {
      this.form.persona_id = val.personas_id || val.id;
    },
    getPlantilla(producto_financiero_id) {
      this.$store
        .dispatch("lineascredito/getRequisitosProducto", producto_financiero_id)
        .then(data => {
          if (data.requisitos) {
            this.$refs.extras.$refs.requisitos.form.requisitosSelected = data.requisitos.map(
              x => x.id
            );
            if (this.$route.params.idLinea) {
              this.setChecklist();
            }
          }
        });
    },
    setDone(val) {
      if (this.lineasReestructuraRenovacion) {
        const method = this.$route.params.esReestructuraORenovacion === 'reestructura' ? 'reestructurar' : 'renovar';
        LineasCreditoApi[method]({ linea_id: this.getLinea.id, lineas: this.lineasReestructuraRenovacion })
      }
      this.finalizado = val;
    },
    setDataEdit() {
      this.form.sucursal_id = this.getLinea.sucursal_id;
      this.form.capital = this.getLinea.capital_autorizado;
      this.form.moneda_id = this.getLinea.moneda_id;
      this.form.producto_financiero_id = this.getLinea.productos_financieros_id;
      this.form.segmento_id = this.getLinea.segmentos_negocio_id;
      this.form.tipo_garantia_id = this.getLinea.tipos_garantia_id
        ? this.getLinea.tipos_garantia_id
        : 0;
      this.form.revolvente = this.getLinea.revolvente ? true : false;
      this.pasivo = this.getLinea.pasiva ? true : false;

      const rolAcreditado = this.pasivo ? "Fuente de fondeo" : "Cliente";

      this.form.persona_id = this.getLinea.relaciones.find(
        x => x.rol.nombre == rolAcreditado
      ).relacionado.id;
      this.cliente = this.getLinea.relaciones
        .find(x => x.rol.nombre == rolAcreditado)
        .relacionado.nombre_completo.split("|")[0];
      
      this.setFechas();
      this.setGarantias();
      this.setPersonas();
      this.setContrato();
    },
    setFechas() {
      this.fechas.fecha_caducidad = this.getLinea.fechaCaducidad
        ? this.$moment(this.getLinea.fechaCaducidad).toDate()
        : null;
      this.fechas.fecha_maxima_cambio_fuente_fondeo = this.getLinea
        .fechaMaximaCambioFuenteFondeo
        ? this.$moment(this.getLinea.fechaMaximaCambioFuenteFondeo).toDate()
        : null;
      this.fechas.fecha_maxima_contratacion = this.getLinea
        .fechaMaximaContratacion
        ? this.$moment(this.getLinea.fechaMaximaContratacion).toDate()
        : null;
      this.fechas.fecha_maxima_disposicion = this.getLinea
        .fechaMaximaDisposicion
        ? this.$moment(this.getLinea.fechaMaximaDisposicion).toDate()
        : null;
    },
    setGarantias() {
      this.$refs.extras.$refs.garantias.garantia.aforo = this.getLinea.valor_proporcional_requerido;
      this.$store
        .dispatch("lineascredito/getGarantias", { linea_id: this.$route.params.idLinea })
        .then(garantias => {
          this.$refs.extras.$refs.garantias.garantia.garantias = garantias.map(
            x => {
              return {
                id: x.garantias_id,
                descripcion: x.garantia.descripcion
              };
            }
          );
        });
    },
    setPersonas() {
      this.$refs.extras.$refs.personas.personas = this.getLinea.relaciones.filter(
        x => {
          return (
            x.persona2_id !=
            this.getLinea.relaciones.find(x => x.rol.nombre == "Cliente")
              .relacionado.id
          );
        }
      );
    },
    setChecklist() {
      this.$store
        .dispatch("lineascredito/getRequisitos", this.$route.params.idLinea)
        .then(data => {
          let difference = data.filter(
            x =>
              !this.$refs.extras.$refs.requisitos.form.requisitosSelected.includes(
                x.requisitos_id
              )
          );
          this.$refs.extras.$refs.requisitos.form.requisitosSelected = data
            .filter(x => {
              if (
                this.$refs.extras.$refs.requisitos.form.requisitosSelected.includes(
                  x.requisitos_id
                )
              ) {
                return x;
              }
            })
            .map(x => x.requisitos_id);
          if (difference) {
            this.$refs.extras.$refs.requisitos.form.requisitosManual = difference.map(
              x => {
                return {
                  descripcion: x.requisito.descripcion,
                  nombre: x.requisito.nombre,
                  tipo_requisito_id: x.requisito.tipo_requisito_id,
                  checked: true
                };
              }
            );
          }
        });
    },
    setContrato() {
      this.$store
        .dispatch("lineascredito/getContrato", this.$route.params.idLinea)
        .then(data => {
          this.$refs.extras.$refs.contrato.form.capital = data.capital;
          this.$refs.extras.$refs.contrato.form.numero = data.numero;
          this.$refs.extras.$refs.contrato.form.descripcion = data.descripcion;
          this.$refs.extras.$refs.contrato.form.fecha_firma = this.$moment(
            data.fecha_firma
          ).toDate();
          this.$refs.extras.$refs.contrato.form.vencimiento_contrato = this.$moment(
            data.vencimiento_contrato
          ).toDate();
          if (data.ciclos) {
            this.$refs.extras.$refs.contrato.form.ciclos = data.ciclos.map(
              x => {
                return [
                  this.$moment(x.fecha_inicial).toDate(),
                  this.$moment(x.fecha_final).toDate()
                ];
              }
            );
          }
        });
    }
  },
  watch: {
    getMe(val) {
      this.form.sucursal_id = val.sucursal_activa
        ? val.sucursal_activa.id
        : null;
    },
    'form.producto_financiero_id'(producto_id, old_producto_id) {
      const producto = this.getProductosFinancieros.find(producto => producto.id === producto_id);
      const oldProducto = this.getProductosFinancieros.find(producto => producto.id === old_producto_id);
      
      if (producto && producto.tipo_credito.tipo === 'Arrendamiento Puro') this.form.tipo_garantia_id = 12;

      this.tieneProductoCreditoGrupal = producto && producto.tipo_credito.tipo === 'Grupo';

      if (
        (producto && producto.tipo_credito.tipo === 'Grupo') ||
        (oldProducto && oldProducto.tipo_credito.tipo === 'Grupo')
      ) {
            this.form.persona_id = null;
            this.cliente = null;
      }
    },
    tieneProductoCreditoGrupal(val) {
      if (val && this.gruposEconomicosCredito.length == 0) {
        CatalogosApi.getGruposEconomicosCredito().then(res => {
          this.gruposEconomicosCredito = res.data;
          this.gruposEconomicosCreditoFiltered = res.data;
        });
      }
    },
    $route: {
      handler: function() {
        if (
          (this.$route.params.idLinea &&
            this.getLinea &&
            this.getLinea.id !== this.$route.params.idLinea) ||
          !this.getLinea
        ) {
          this.$store.dispatch("lineascredito/resetState");
          if (this.$route.params.idLinea) {
            if (this.getLinea == null) {
              this.$store
                .dispatch(
                  "lineascredito/getLineaById",
                  this.$route.params.idLinea
                )
                .then(() => {
                  this.setDataEdit();
                });
            } else {
              this.setDataEdit();
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.pasivo = this.$route.params.pasiva;
    this.lineasReestructuraRenovacion = this.$route.params.lineasReestructuraRenovacion;

    if (this.$route.params.clienteReestructuraRenovacionId) {
      const cliente = { id: this.$route.params.clienteReestructuraRenovacionId }
      this.setCliente(cliente);
      this.cliente = this.getClientes.find(user => user.personas_id === cliente.id)?.alias;
    }

    if (!this.pasivo && this.getClientes == null) {
      await this.$store.dispatch("catalogos/getClientes");
    }
    if (this.pasivo && this.getFuentesFondeo == null) {
      await this.$store.dispatch("catalogos/getFuentesFondeo");
    }
    if (this.getMonedas.length == 0) {
      await this.$store.dispatch("catalogos/getMonedas");
    }
    if (this.getProductosFinancieros.length == 0) {
      await this.$store.dispatch("catalogos/getProductosFinancieros");
    }
    if (this.getSegmentoNegocio == null) {
      await this.$store.dispatch("catalogos/getSegmentoNegocio");
    }
    if (this.getTiposGarantiaLinea == null) {
      await this.$store.dispatch("catalogos/getTiposGarantiaLinea");
    }
    if (this.$route.params.idLinea) {
      if (this.getLinea == null) {
        this.$store
          .dispatch("lineascredito/getLineaById", this.$route.params.idLinea)
          .then(() => {
            this.setDataEdit();
          });
      } else {
        this.setDataEdit();
      }
    }
  }
};
</script>

<style lang="scss">
.resumen {
  position: sticky;
  top: 5rem;
}
</style>
